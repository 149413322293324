// extracted by mini-css-extract-plugin
export var achievementsContainer = "reviews-module--achievementsContainer--17XA1";
export var achievementsGrid = "reviews-module--achievementsGrid--4d+6l";
export var partnersContainer = "reviews-module--partnersContainer--36EoP";
export var reviewCard = "reviews-module--reviewCard--E0Aaw";
export var reviewDate = "reviews-module--reviewDate---UDx5";
export var reviewImg = "reviews-module--reviewImg--XJ2jE";
export var reviewName = "reviews-module--reviewName--V80Sy";
export var reviewStar = "reviews-module--reviewStar--Fx-UI";
export var reviewSvg = "reviews-module--reviewSvg--6t1gj";
export var reviewText = "reviews-module--reviewText--Sh7YT";
export var reviewsContainer = "reviews-module--reviewsContainer--z3ssN";
export var reviewsGrid = "reviews-module--reviewsGrid--lJKY8";
export var reviewsLinks = "reviews-module--reviewsLinks--2uOBi";
export var reviewsNameContainer = "reviews-module--reviewsNameContainer--y+WKS";
export var reviewsTopContainer = "reviews-module--reviewsTopContainer--4sCg6";
export var socialContainer = "reviews-module--socialContainer--bgiM0";
export var socialGrid = "reviews-module--socialGrid--YglL5";
export var socialText = "reviews-module--socialText--G2Lcp";
export var socialTitle = "reviews-module--socialTitle--NhsFX";